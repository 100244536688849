import cx from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import getCountryFlag from 'application/utils/get-country-flag';
import { ResponsiveFlex } from 'ui/responsive-flex/responsive-flex';
import { Space } from 'ui/space/space';
import { Text } from 'ui/v2/typography/text';

import css from './opponent.module.scss';

type OpponentType = 'left' | 'right' | 'center';

interface OpponentProps {
  className?: string;
  name: string;
  country?: string | null;
  rank?: string | null;
  image?: string | null;
  score?: string;
  coeff?: string;
  volume?: string;
  win?: boolean | null;
  rated?: boolean;
  selected?: boolean;
  choosed?: boolean;
  disabled?: boolean;
  type?: OpponentType;
  onClick?: () => void;
  noShadow?: boolean;
}

const Opponent: React.FC<OpponentProps> = ({
  className,
  name,
  country,
  rank,
  image,
  score = '',
  coeff = '',
  volume = '',
  win,
  rated,
  selected,
  choosed,
  onClick,
  type,
  noShadow,
  disabled = false,
}) => {
  const { t } = useTranslation(['fights']);
  const CountryNode = getCountryFlag(country);

  const highlight = win === null && (selected || rated) && type;
  const isActive = onClick && !disabled && !rated && win === undefined;
  const isDisabled = win !== null || (!rated && disabled);

  const typeMap: Map<OpponentType, any> = new Map()
    .set('left', css.LeftSelected)
    .set('right', css.RightSelected)
    .set('center', css.CenterSelected);

  const hightligtMap: Map<OpponentType, any> = new Map()
    .set('left', css.LeftHightlighted)
    .set('right', css.RightHightlighted)
    .set('center', css.CenterHightlighted);

  const winMap: Map<boolean, any> = new Map()
    .set(true, css.WinResult)
    .set(false, css.LoseResult);

  const isEnded = win !== null;
  const isRated = isEnded && rated;
  const isSelected = !isEnded && selected;

  return (
    <div
      className={cx(
        isActive ? css.ActiveRoot : css.Root,
        isDisabled && css.DisabledRoot,
        highlight && typeMap.get(type),
        rated && winMap.get(win!),
        selected && rated && css.Confirmed,
        choosed && hightligtMap.get(type!),
        className
      )}
      onClick={onClick}
    >
      {rank && (
        <div className={css.Rank}>
          <Text
            type="caption3"
            color={isSelected || isRated ? 'white-color' : 'grey-primary-7'}
          >
            № {rank}
          </Text>
        </div>
      )}
      {CountryNode && (
        <div className={css.Flag}>
          <CountryNode />
        </div>
      )}
      <div style={{ position: 'relative', width: '100%' }}>
        <div
          className={cx(css.Image)}
          style={{
            backgroundImage: `url(${image || '/images/no-image.png'})`,
          }}
        />
        {!noShadow && (
          <div
            className={cx(css.ImageShadow, highlight && css.ImageShadowActive)}
          />
        )}
      </div>

      {/* --- Mobile --- */}

      <ResponsiveFlex
        hide
        flexDirection="column"
        mobile={{ hide: false }}
        width="100%"
      >
        <Space size={8} />
        <Text
          type="caption"
          color={isSelected || isRated ? 'white-color' : 'black-primary'}
          textAlign="center"
          className={css.NameText}
          strong
        >
          {name}
        </Text>

        <Space size={0} />
        {win === null && (
          <Text
            normal
            textAlign="center"
            type="caption2"
            color={isSelected ? 'white-color' : 'grey-primary-7'}
            opacity={isSelected ? 0.7 : 1}
          >
            {score}
          </Text>
        )}
        {win == true && (
          <Text
            bold
            uppercase
            textAlign="center"
            type="caption3"
            color={isRated ? 'white-color' : 'positive-color'}
          >
            {t('fights:win')}
          </Text>
        )}

        {win == false && (
          <Text
            bold
            uppercase
            textAlign="center"
            type="caption3"
            opacity={isRated ? 0.7 : 1}
            color={isRated ? 'white-color' : 'grey-primary'}
          >
            {t('fights:loss')}
          </Text>
        )}

        <Space size={0} />
        <Text
          textAlign="center"
          type="caption2"
          color={isSelected || isRated ? 'white-color' : 'blue-primary'}
          bold
        >
          {coeff}
        </Text>
        {volume && (
          <>
            <Space size={2} />
            <Text
              textAlign="center"
              type="caption2"
              color={isSelected || isRated ? 'white-color' : undefined}
            >
              {volume}
            </Text>
          </>
        )}
      </ResponsiveFlex>

      {/* --- PC --- */}

      <ResponsiveFlex flexDirection="column" mobile={{ hide: true }}>
        <Space size={10} />
        <Text
          type="caption2"
          color={isSelected || isRated ? 'white-color' : 'black-primary'}
          textAlign="center"
          strong
        >
          {name}
        </Text>
        <Space size={2} />
        {win === null && (
          <Text
            normal
            textAlign="center"
            type="caption3"
            color={isSelected ? 'white-color' : 'grey-primary-7'}
            style={{ lineHeight: '130%' }}
          >
            {score}
          </Text>
        )}
        {win == true && (
          <Text
            bold
            uppercase
            textAlign="center"
            type="caption3"
            color={isRated ? 'white-color' : 'positive-color'}
          >
            {t('fights:win')}
          </Text>
        )}
        {win == false && (
          <Text
            bold
            uppercase
            textAlign="center"
            type="caption3"
            opacity={isRated ? 0.7 : 1}
            color={isRated ? 'white-color' : 'grey-primary'}
          >
            {t('fights:loss')}
          </Text>
        )}
        <Space size={2} />
        <Text
          textAlign="center"
          type="caption2"
          color={isSelected || isRated ? 'white-color' : 'blue-primary'}
          bold
        >
          {coeff}
        </Text>
        {volume && (
          <>
            <Space size={2} />
            <Text
              textAlign="center"
              type="caption2"
              color={isSelected || isRated ? 'white-color' : undefined}
            >
              {volume}
            </Text>
          </>
        )}
      </ResponsiveFlex>
    </div>
  );
};

export default Opponent;
