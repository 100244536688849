import cs from 'classnames';
import React, { CSSProperties } from 'react';

import css from './circle-progress-bar.module.scss';

interface CircleProgressBarProps {
  progress: number;
}

const CircleProgressBar: React.FC<CircleProgressBarProps> = ({ progress }) => {
  return (
    <div className={css.Root}>
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        className={css.CircularProgress}
        style={
          {
            '--final-progress': progress,
          } as CSSProperties
        }
      >
        <circle className={css.Bg}></circle>
        <circle className={css.Fg}></circle>
      </svg>
      <div
        className={cs(
          css.Progress,
          progress >= 100 ? css.ColorGreen : css.ColorRed
        )}
      >
        {progress}%
      </div>
    </div>
  );
};

export default CircleProgressBar;
